// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { env } from "ng-viv-ux";

export const environment: env = {
  production: true,
  sdk: import('ng-vex-sdk'),
  dark_logo: 'magnum_logo.png',
  light_logo: 'magnum_logo.png',
  title: 'Magnum',
  version: require('../../package.json').version,
  apiAccessPoint: {
    protocol: 'https',
    hostname: 'srv.magnum-d.bg',
    path: 'api'
  },
  // This shoud be removed
  showLabelKeyIfNotFound: true,
  // tslint:disable-next-line: max-line-length
  apiToken: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiNzVkOGVmYTg3ZWEwOWYzMzI0Mjg5ZGU1ZTgxMGJlODQwNzJlMDkyMDQxYjZhMmY2Zjc4MDE4YTkwNTA3MGY5Mzk4ODViOTNjMmZhZDhiZDgiLCJpYXQiOjE3MjE4ODg1MjUuNTQ2MTEsIm5iZiI6MTcyMTg4ODUyNS41NDYxMTUsImV4cCI6MTc1MzQyNDUyNS41Mzg1LCJzdWIiOiIiLCJzY29wZXMiOltdfQ.WaS9PMAg6jWeBv3TaEHMWOmpU11Dpl8Fs6JUG2PpKNev27zNUVY6znMAUEBA44FBOj2Z9iO7ix1goAZL9Fw9n7jOhceX_k61MfTEwSMnU8YQfU_Ni-FLdbaYs4vrepIPbJSBLJYJoO6p1H7I6XPkDWoelgOT-G6o-_KX7UXRPv4axUZuaprIV9oGRu7O4YXPq7u5YVVWrdpd4N40dGh8kcaMwZjd0zJfD_o1M4K6V5fsFKrIWS5c1v3wrjJ7XmU2SqZN0RSl9hBgciRmFk37IXAcvSTr1IftT82EUZQHZMEC4plljQRXBNiMxoepfH4U54Sw4bW98WqwTm4lxBTYH8BYHb2fUep1DPy5tJXCzmsBmt78nrP3KnzdeDpqvhIQUmY6YSl5CeC5-Me2yVKKoDzalKL2tJAvBKXAD6xSwwouBgCbB1e8a1pQi0Xh708p9ldoPM5zSyb12mHIBk-m0zxlvJ6sFWE3hWYEAhr9Ruf9kLuKic82L1p5plMmBcpMoSNOJ5c5s1g3kTWyibIWuoYbzn8CSFzWNjn4-8zI3QhUIfA1o0il4FYHIgV2Jxu38JsnD1bJoKYd9j6czT789Q8u3-AyWEq2NZnpiAGR1cl1kf_6ILwakPizSrSEsQOPsYcn-Fd7MZWf3O_p5NS33g2aZZJLqPtp96cs-8QFl44',
  mapApiToken: 'AIzaSyCfecV8GfLbgx6Ciw3uDhcPV3S-UtarrvQ',
  recaptchaApiToken: '6LcEjiEgAAAAADk_cANagYtJqPG7Cx3ZkrwilUfO',
  noLoaderRequests: [
    {
      url: '/api',
      body: {
        method: 'app.locale.getLocales'
      }
    },
    {
      url: '/api',
      body: {
        method: 'access.user.checkSession'
      }
    },
    {
      url: '/api',
      body: {
        method: 'app.label.getPublished'
      }
    },
    {
      url: '/api',
      body: {
        method: 'menu.menu.view'
      }
    },
    {
      url: '/api',
      body: {
        method: 'banner.banner.view'
      }
    },
    {
      url: '/api',
      body: {
        method: 'product.product.list'
      }
    },
    {
      url: '/api',
      body: {
        method: 'order.cart.loadCart'
      }
    },
    {
      url: '/api',
      body: {
        method: 'product.product.view'
      }
    },
    {
      url: '/api',
      body: {
        method: 'product.product.lastViewedProducts'
      }
    },
    {
      url: './assets/routing-mock.json?v=1.0.0'
    }
  ]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
